import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//mui imports
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import { getNFLCurrentMatchup } from '../../../api';

export default function Champion() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentMatchup, setCurrentMatchup] = useState(null);

  useEffect(() => {
    getNFLCurrentMatchup()
      .then((response) => {
        setCurrentMatchup(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          justify="center"
          color="text.primary"
          gutterBottom
        >
          There was an issue loading the current NFL Title Belt matchup.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 8, pb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs="12">
          <Typography
            component="h2"
            variant="h4"
            align="center"
            justify="center"
            color="text.primary"
            gutterBottom
          >
            {isLoading ? <Skeleton /> : '2024 NFL Title Belt Champion'}
          </Typography>
        </Grid>
        <Grid item xs="12">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link
                to={`/nfl/${currentMatchup?.holder?.slug}`}
                variant="body"
                style={{ textDecoration: 'inherit' }}
              >
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={75} width={75} />
                  ) : (
                    <img
                      src="https://i.logocdn.com/nfl/2012/seattle-seahawks.svg"
                      width={250}
                      alt="2024  NFL Title Belt Champion Cleveland Browns"
                    />
                  )}
                  <Typography
                    component="h3"
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                  >
                    {isLoading ? (
                      <Skeleton width={225} />
                    ) : (
                      currentMatchup?.holder?.name
                    )}
                  </Typography>
                </Stack>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography color="text.primary">
            <em>
              The Seattle Seahakwss failed to qualify for the postseason,
              meaning the NFL Title Belt will not be on the line in this years
              NFL Playoffs. The Belt will next be contested in Week 1 of the
              2025 NFL Season.
            </em>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
